import React, { useRef, useState } from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Select } from 'antd';
import { Header } from 'antd/es/layout/layout';
import { useNavigate } from 'react-router-dom';
import { MenuOutlined } from '@ant-design/icons';
import logoTexto from '../../assets/logoTexto.webp';
import widthHeightSelector from '../../redux/selectors/widthHeightSelector';
import restaurantSelector from '../../redux/selectors/restaurantSelector';
import { setRestaurant } from '../../redux/actions/restaurantActions';

import './UsersHeader.css';

function UsersHeader({ isScrolled }) {
  const { type } = useSelector(widthHeightSelector);
  const [isOpen, setIsOpen] = useState(false);
  const { number } = useSelector(restaurantSelector);
  const ref = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleChange = (e) => {
    dispatch(setRestaurant(e));
  };

  const handleOpen = (route) => {
    navigate(route);
  };

  return (
    <Header className="p-0 m-0" style={{ zIndex: '999', backgroundColor: 'transparent' }}>
      <Navbar id="nav" ref={ref} style={{ backgroundColor: isOpen ? '#221E1F' : 'transparent' }} className={`p-0 navbar-custom ${isScrolled ? 'scrolled' : ''}`} collapseOnSelect expand="lg">
        <Container className="d-flex justify-content-around container-header">
          <Navbar.Brand className="size_animate" style={{ cursor: 'pointer' }}>
            <Nav.Link className={`size_animate m-0  ${isScrolled ? 'items-scrolled' : 'items-not-scrolled'}`} href="/#welcome">
              <img src={logoTexto} alt="Taquito grill" style={{ height: type === 'sm' ? '40px' : '50px' }} />
            </Nav.Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" style={{ padding: '7px', borderColor: 'white' }} onClick={() => { setIsOpen(!isOpen); }}>
            <div className="d-flex flex-column justify-content-center">
              <MenuOutlined style={{ color: 'white' }} />
            </div>
          </Navbar.Toggle>
          <Navbar.Collapse id="responsive-navbar-nav" style={{ backgroundColor: isOpen ? '#221E1F' : 'transparent' }} className={`${isScrolled ? 'nav-scrolled' : 'nav-not-scrolled'}`}>
            <Nav className="me-auto d-flex justify-content-center w-100">
              <Nav.Link className={`size_animate m-2 ${isScrolled ? 'items-scrolled' : 'items-not-scrolled'}`} onClick={() => handleOpen('/')}>Inicio</Nav.Link>
              <Nav.Link
                className={`size_animate m-2 ${isScrolled ? 'items-scrolled' : 'items-not-scrolled'}`}
                href={`https://${number === 1 ? 'dgo' : 'mzt'}.taquitogrill.com`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Menú
              </Nav.Link>
              <Nav.Link className={`size_animate m-2 ${isScrolled ? 'items-scrolled' : 'items-not-scrolled'}`} onClick={() => handleOpen('/calendario')}>Calendario</Nav.Link>
              <Nav.Link className={`size_animate m-2 ${isScrolled ? 'items-scrolled' : 'items-not-scrolled'}`} onClick={() => handleOpen('/promociones')}>Promociones</Nav.Link>
              <Nav.Link className={`size_animate m-2 ${isScrolled ? 'items-scrolled' : 'items-not-scrolled'}`} onClick={() => handleOpen('/contacto')}>Contacto</Nav.Link>
              <Select
                onChange={handleChange}
                value={number}
                className="select-restaurant"
                options={[
                  { value: 1, label: 'Durango' },
                  { value: 2, label: 'Mazatlán' },
                ]}
                style={{ width: 180, height: '45px', backgroundColor: 'transparent' }}
              />
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </Header>
  );
}
export default UsersHeader;
