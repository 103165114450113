import getWidthType from '../../utils/redux/reducers/getWidthType';
import { SET_WIDTH_HEIGHT } from '../actions/widthHeightActions';

const { innerWidth, innerHeight } = window;

const initialState = {
  width: innerWidth,
  height: innerHeight,
  type: getWidthType(innerWidth),
};

// eslint-disable-next-line default-param-last
const widthHeightReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_WIDTH_HEIGHT:
      return {
        ...state,
        width: action.payload.width,
        height: action.payload.height,
        type: getWidthType(action.payload.width),
      };
    default:
      return state;
  }
};

export default widthHeightReducer;
