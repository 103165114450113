import React, { useEffect, useRef, useState } from 'react';
import { Layout } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { setWidthHeight } from '../redux/actions/widthHeightActions';
import getWindowSize from '../utils/getWindowSize';
import UsersFooter from './Users/UsersFooter';
import UsersWelcome from './Users/UsersWelcome';
import UsersGallery from './Users/UsersGallery';
import UsersInfo from './Users/UsersInfo';
import UsersHeader from './Users/UsersHeader';
import UsersVisitUs from './Users/UsersVisitUs';
import backgroundImage from '../assets/welcome.webp';
import useGetRequest from '../hooks/useGetRequest';
import { setAllData } from '../redux/actions/restaurantActions';
import restaurantSelector from '../redux/selectors/restaurantSelector';
import UsersText from './Users/UsersText';
import UsersShowMore from './Users/UsersShowMore';
import UsersCalendar from './Users/UsersCalendar';

const canonicals = {
  '/durango': 'https://taquitogrill.com/durango',
  '/mazatlan': 'https://taquitogrill.com/mazatlan',
};

function HomeUsers() {
  const { number } = useSelector(restaurantSelector);
  const { data, isLoading, refetch } = useGetRequest('restaurantData', '/taquito/users/restaurant/info', { number }, true, false);
  const dispatch = useDispatch();
  const [isScrolled, setIsScrolled] = useState(false);
  const { name, facebook, email, instagram, phoneNumber, address, mapsSrc, mapsDir } = data?.info || {};
  const firstPaint = useRef(true);
  const location = useLocation();
  useEffect(() => {
    if (!isLoading) dispatch(setAllData(name, facebook, email, instagram, phoneNumber, address, mapsSrc, mapsDir, data.promotions || []));
  }, [data]);

  useEffect(() => {
    if (firstPaint.current)firstPaint.current = false;
    else refetch();
  }, [number]);

  useEffect(() => {
    const handleWindowResize = () => {
      const { width: width2, height } = getWindowSize();
      dispatch(setWidthHeight(width2, height));
    };
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  const handleScroll = ({ target }) => {
    if (target.scrollTop > 0 && !isScrolled) {
      setIsScrolled(true);
    } else if (target.scrollTop === 0) {
      setIsScrolled(false);
    }
  };

  return (
    <div>

      <Layout
        style={{ height: '100vh' }}
      >
        <Helmet>
          <link rel="canonical" href={canonicals[location.pathname] || 'https://taquitogrill.com'} />
        </Helmet>
        <div
          style={{
            backgroundImage: `url(${backgroundImage})`,
            backgroundSize: 'cover',
            filter: 'blur(4px) brightness(35%)',
            backgroundPosition: 'center',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
          }}
        />
        <UsersHeader isScrolled={isScrolled} />
        <Content
          onScroll={handleScroll}
          style={{ overflow: 'auto', position: 'relative', zIndex: 1 }}
        >
          <UsersWelcome isScrolled={isScrolled} />
          <UsersText />
          <UsersCalendar />
          <UsersGallery isScrolled={isScrolled} />
          <UsersShowMore />
          <UsersInfo isScrolled={isScrolled} />
          <UsersVisitUs isScrolled={isScrolled} />
          <UsersFooter isScrolled={isScrolled} />
        </Content>
      </Layout>
      <Outlet />

    </div>
  );
}

export default HomeUsers;
