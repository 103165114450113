import React from 'react';
import { Card, Flex, Typography } from 'antd';
import { useSelector } from 'react-redux';
import LazyLoad from 'react-lazy-load';
import restaurantSelector from '../../redux/selectors/restaurantSelector';
import tacosImg from '../../assets/text/tacos.webp';

import colors from '../../utils/colors';
import './UsersText.css';

function UsersText() {
  const { number } = useSelector(restaurantSelector);

  return (
    <Flex justify="center" style={{ backgroundColor: '#EEEEEE' }}>
      <Flex className="container" vertical>
        <Typography.Title level={3} className="users-text-title">
          {`Bienvenido a Taquito Grill: El Mejor Restaurante de Tacos en ${number === 1 ? 'Durango' : 'Mazatlán'}`}
        </Typography.Title>
        <Flex className="container-sections">
          <Flex vertical className="section-container" style={{ flexBasis: '50%', flexGrow: 1 }}>
            <Card className="section-card" style={{ height: '100%' }}>
              <Typography.Title level={4}>Descubre nuestros exquisitos tacos</Typography.Title>
              <Flex vertical>
                <Typography.Paragraph>
                  Nuestros tacos son el alma de Taquito Grill.
                </Typography.Paragraph>
                <Typography.Paragraph>
                  Desde los clásicos tacos de carne asada hasta los populares tacos al pastor,
                  cada bocado está lleno de sabor y calidad.
                </Typography.Paragraph>
                <Typography.Paragraph>
                  Utilizamos solo los mejores ingredientes para asegurarnos de que cada taco sea
                  una experiencia inolvidable. ¿Buscas los mejores tacos en Durango? No busques más, en Taquito Grill encontrarás lo que necesitas.
                </Typography.Paragraph>
                <Flex justify="center">
                  <LazyLoad>
                    <img src={tacosImg} alt="Imagen de tacos" className="users-text-img" />
                  </LazyLoad>
                </Flex>
              </Flex>
            </Card>
          </Flex>
          <Flex vertical className="section-container" style={{ flexBasis: '50%', flexGrow: 1 }}>
            <Card className="section-card" style={{ height: '100%' }}>
              <Flex vertical>
                <Typography.Title level={4}>Un menú para todos los gustos</Typography.Title>
                <Flex vertical>
                  <Typography.Paragraph>
                    En
                    {' '}
                    <strong>Taquito Grill</strong>
                    , nos especializamos en ofrecer una amplia variedad de platos para satisfacer todos los gustos. Nuestro menú incluye:
                  </Typography.Paragraph>
                  <ul className="menu-list">
                    <li className="menu-list-item">
                      <strong>Cortes de carne:</strong>
                      {' '}
                      Disfruta de nuestros selectos cortes de carne preparados a la perfección.
                    </li>
                    <li className="menu-list-item">
                      <strong>Hamburguesas:</strong>
                      {' '}
                      Jugosas y sabrosas, nuestras hamburguesas son una delicia.
                    </li>
                    <li className="menu-list-item">
                      <strong>Ensaladas frescas:</strong>
                      {' '}
                      Perfectas para quienes prefieren opciones más ligeras.
                    </li>
                    <li className="menu-list-item">
                      <strong>Menú infantil:</strong>
                      {' '}
                      Opciones deliciosas y nutritivas para los más pequeños.
                    </li>
                    <li className="menu-list-item">
                      <strong>Postres deliciosos:</strong>
                      {' '}
                      Endulza tu paladar con nuestra selección de postres.
                    </li>
                  </ul>
                </Flex>
                <Flex vertical align="center">
                  <Typography.Text className="mb-3">
                    Conoce nuestro menú completo desde el siguiente enlace:
                  </Typography.Text>
                  <Flex justify="center">
                    <a
                      role="button"
                      href={`https://${number === 1 ? 'dgo' : 'mzt'}.taquitogrill.com`}
                      style={{ height: '45px', width: '120px', fontSize: '20px', fontWeight: 600, borderRadius: 100, color: '#0F0F0F', textDecoration: 'none', backgroundColor: colors.primary[0] }}
                      className="d-flex justify-content-center align-items-center"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Ver menú
                    </a>
                  </Flex>
                </Flex>
              </Flex>
            </Card>
          </Flex>
        </Flex>

      </Flex>
    </Flex>
  );
}

export default UsersText;
