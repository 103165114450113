export const SET_RESTAURANT = 'SET_RESTAURANT';
export const SET_DATA = 'SET_All_DATA';

export const setRestaurant = (number) => ({
  type: SET_RESTAURANT,
  payload: { number },
});

export const setAllData = (name, facebook, email, instagram, phoneNumber, address, mapsSrc, mapsDir, promotions) => ({
  type: SET_DATA,
  payload: {
    name,
    facebook,
    email,
    instagram,
    phoneNumber,
    address,
    mapsSrc,
    mapsDir,
    promotions,
  },
});
