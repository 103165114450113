import { Button, DatePicker } from 'antd';
import React from 'react';
import esES from 'antd/es/locale/es_ES';

import './UsersCalendarDates.css';
import { useSelector } from 'react-redux';
import { EditFilled } from '@ant-design/icons';
import widthHeightSelector from '../../redux/selectors/widthHeightSelector';

const pickerBiType = {
  day: null,
  week: 'week',
  month: 'month',
};

const formatByType = {
  day: 'dddd, DD-MM-YYYY',
  week: null,
  month: 'MMMM YYYY',
};

function UsersCalendarDates({ type, dates, setDates }) {
  const { width } = useSelector(widthHeightSelector);

  const onChange = (date) => {
    setDates([date.startOf(type), date.endOf(type)]);
  };

  return (
    <DatePicker
      className="custom-picker"
      value={dates}
      onChange={onChange}
      picker={pickerBiType[type]}
      allowClear={false}
      locale={esES}
      format={formatByType[type]}
      style={{ border: '0px', backgroundColor: 'transparent', color: '#F4AB66' }}
      suffixIcon={(
        <Button
          icon={(width < 450) ? <EditFilled style={{ color: '#F4AB66' }} /> : null}
          type={(width < 450) ? 'text' : 'primary'}
        >
          {(width < 450) ? '' : 'Cambiar fecha'}
        </Button>
)}
    />
  );
}

export default UsersCalendarDates;
