import { Spin } from 'antd';
import React from 'react';

function LazyLoading() {
  return (
    <div className="d-flex vh-100 align-items-center justify-content-center">
      <Spin />
    </div>
  );
}

export default LazyLoading;
