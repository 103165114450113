import { PhoneOutlined, WhatsAppOutlined } from '@ant-design/icons';
import { Flex, Space, Typography } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import restaurantSelector from '../../redux/selectors/restaurantSelector';

import './UsersEmptyCard.css';

function UsersEmptyCard() {
  const { number, phoneNumber } = useSelector(restaurantSelector);

  return (
    <Flex
      key="empty"
      className="container-empty-card"
      vertical
      justify="space-around"
      align="center"
    >
      <Typography.Title level={5} style={{ color: 'white' }}>¿No encuentra su deporte?</Typography.Title>
      <Typography.Text style={{ color: 'white' }}>Contáctenos o pregunte a su mesero</Typography.Text>
      <Flex vertical align="center" className="mt-2">
        <Space>
          <a
            href={`tel:${phoneNumber}`}
            rel="noopener noreferrer"
            aria-label="Llámanos"
            style={{ textDecoration: 'none' }}
          >
            <PhoneOutlined className="m-0" style={{ fontSize: 25, color: '#f47b22' }} />
          </a>
          <a
            href={`https://wa.me/52${number === 1 ? '6181697164' : '6692504030'}`}
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Escríbenos"
            style={{ textDecoration: 'none' }}
          >
            <WhatsAppOutlined className="m-0" style={{ fontSize: 25, color: '#f47b22' }} />
          </a>
        </Space>
      </Flex>
    </Flex>
  );
}

export default UsersEmptyCard;
