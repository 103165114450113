import React, { useState } from 'react';
import { Flex, Typography } from 'antd';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import UsersCalendarType from '../../components/UsersCalendar/UsersCalendarType';
import UsersCalendarDates from '../../components/UsersCalendar/UsersCalendarDates';
import UsersCalendarCards from '../../components/UsersCalendar/UsersCalendarCards';
import useGetRequest from '../../hooks/useGetRequest';
import restaurantSelector from '../../redux/selectors/restaurantSelector';

import './UsersCalendar.css';
import isValidType from '../../utils/calendar/isValidType';
import isValidDates from '../../utils/calendar/isValidDates';

function UsersCalendar({ fromHomeCalendar }) {
  const { dateType, date } = useParams();
  const [type, setType] = useState(isValidType(dateType) ? dateType : 'day');
  const [dates, setDates] = useState(isValidDates(date) || [dayjs().startOf(type), dayjs().endOf(type)]);
  const { number } = useSelector(restaurantSelector);
  const { data, isLoading } = useGetRequest(['events', dates, number], '/taquito/users/restaurant/events', { numberRestaurant: number, from: dates[0].format('YYYY-MM-DD'), to: dates[1].format('YYYY-MM-DD') });

  return (
    <Flex className="users-calendar-top-container" style={{ height: fromHomeCalendar ? 'auto' : '90vh' }}>
      <Flex vertical align="center" className="users-calendar-container h-100">
        <Flex wrap="wrap" align="center" justify="space-between" className="title-container">
          <Typography.Title level={2} style={{ color: 'white', marginBottom: 5 }}>Calendario deportivo</Typography.Title>
          <UsersCalendarType dates={dates} setDates={setDates} setType={setType} type={type} />
        </Flex>
        <Flex justify="end" className="w-100">
          <UsersCalendarDates type={type} setDates={setDates} dates={dates} />
        </Flex>
        <UsersCalendarCards data={data || []} isLoading={isLoading} fromHomeCalendar={fromHomeCalendar} type={type} dates={dates} />
        <Flex />
      </Flex>
    </Flex>
  );
}

export default UsersCalendar;
