import { Flex, Space, Typography } from 'antd';
import React from 'react';
import dayjs from 'dayjs';

import './UsersTwoTeamsCard.css';

import soccer from '../../assets/sports/soccer.svg';
import football from '../../assets/sports/football.svg';
import baseball from '../../assets/sports/baseball.svg';
import basketball from '../../assets/sports/basketball.svg';

const getIconByNumber = {
  1: soccer,
  2: basketball,
  3: baseball,
  5: football,
};

function UsersTwoTeamsCard({ item }) {
  return (
    <Flex
      key={item.id}
      className="container-card"
      vertical
    >
      <div
        className="blur-background"
        style={{ backgroundImage: `url(${item.backgroundImage})` }}
      />
      <Flex>
        <Flex className="container-icon">
          <img className="svg-color-white" loading="lazy" width={110} height={110} src={getIconByNumber[item.numberSport]} alt={item.teamLocal} />
        </Flex>
        <Flex vertical justify="space-around" align="center" className="content-card">
          {item.esName
          && (
          <Flex justify="center">
            <Typography.Title level={5} style={{ color: 'white' }}>{item.esName}</Typography.Title>
          </Flex>
          )}
          <Flex justify="center">
            <Space className="w-100">
              <Typography.Text strong style={{ color: 'white' }}>{item.teamLocal}</Typography.Text>
              <Typography.Text strong style={{ color: 'white', whiteSpace: 'nowrap' }}>VS</Typography.Text>
              <Typography.Text strong style={{ color: 'white' }}>{item.teamVisitant}</Typography.Text>
            </Space>
          </Flex>
          <Flex justify="center">
            <Typography.Text strong style={{ color: 'white' }}>
              {dayjs
                .unix(item.startTime)
                .format('DD [de] MMMM hh:mm a')}
            </Typography.Text>
          </Flex>
        </Flex>
      </Flex>
    </Flex>

  );
}

export default UsersTwoTeamsCard;
