function formatPhoneNumber(phoneNumber) {
  if (!phoneNumber) return '';
  const phoneNumberStr = phoneNumber.toString();
  const part1 = phoneNumberStr.slice(0, 3);
  const part2 = phoneNumberStr.slice(3, 6);
  const part3 = phoneNumberStr.slice(6, 10);
  const formattedPhoneNumber = `+52${part1}${part2}${part3}`;
  return formattedPhoneNumber;
}
export default formatPhoneNumber;
