export const SET_ADMIN_DATA = 'SET_ADMIN_DATA';
export const DELETE_ADMIN_DATA = 'DELETE_ADMIN_DATA';

export const setAdminData = (id, token, idRestaurant, email, isSuper, name) => {
  localStorage.setItem('token', token);
  return ({
    type: SET_ADMIN_DATA,
    payload: { id, token: `Bearer ${token}`, idRestaurant, email, isSuper, name },
  });
};

export const deleteAdminData = () => {
  localStorage.removeItem('token');
  return ({ type: DELETE_ADMIN_DATA });
};
