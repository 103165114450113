import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ConfigProvider } from 'antd';
import { Provider } from 'react-redux';
import esES from 'antd/es/locale/es_ES';
import { Helmet } from 'react-helmet';
import dayjs from 'dayjs';
import Router from './router/Router';
import store from './redux/store';
import colors from './utils/colors';
import 'dayjs/locale/es-mx';

const queryClient = new QueryClient();

function App() {
  dayjs.locale('es-mx');

  return (
    <Provider store={store}>
      <ConfigProvider
        theme={{ token: { colorPrimary: colors.primary[0], borderRadius: 16 } }}
        locale={esES}
      >
        <QueryClientProvider client={queryClient}>
          <Helmet>
            <title>Taquito Grill. El sabor de los deportes.</title>
            <meta name="keywords" content="Taquito Grill, Restaurante Durango, Restaurante Mazatlán, Tacos, Tacos Durango, Tacos Mazatlán, Restaurante Deportivo" />
            <meta name="description" content="Disfruta de tus deportes favoritos y deliciosos tacos en uno de nuestros restaurantes deportivos, visítanos en Durango o en Mazatlán. ¡Ven y prueba nuestros cortes, parrilladas y más!" />
            <meta httpEquiv="content-language" content="es" />
          </Helmet>
          <Router />
        </QueryClientProvider>
      </ConfigProvider>
    </Provider>
  );
}

export default App;
