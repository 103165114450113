import { Flex, Typography } from 'antd';
import React from 'react';
import dayjs from 'dayjs';

import f1 from '../../assets/sports/f1.svg';

import './UsersTwoTeamsCard.css';

function UsersTwoTeamsCard({ item }) {
  return (
    <Flex
      key={item.id}
      className="container-card"
      vertical
    >
      <div
        className="blur-background"
        style={{ backgroundImage: `url(${item.backgroundImage})` }}
      />
      <Flex>
        <Flex className="container-icon">
          <img className="svg-color-white" loading="lazy" width={110} height={110} src={f1} alt={item.teamLocal} />
        </Flex>
        <Flex vertical justify="space-around" align="center" className="content-card">
          <Flex justify="center">
            <Typography.Title level={5} strong style={{ color: 'white' }}>{item.teamLocal}</Typography.Title>
          </Flex>
          <Flex vertical align="center">
            <Typography.Title level={5} strong style={{ color: 'white' }}>{item.teamVisitant}</Typography.Title>
          </Flex>
          <Flex justify="center">
            <Typography.Text strong style={{ color: 'white' }}>
              {dayjs
                .unix(item.startTime)
                .format('DD [de] MMMM hh:mm a')}
            </Typography.Text>
          </Flex>
        </Flex>
      </Flex>
    </Flex>

  );
}

export default UsersTwoTeamsCard;
