import { Flex, Typography } from 'antd';
import React, { useState } from 'react';
import PhotoAlbum from 'react-photo-album';
import Lightbox from 'yet-another-react-lightbox';
// eslint-disable-next-line import/no-unresolved
import 'yet-another-react-lightbox/styles.css';

import { useSelector } from 'react-redux';
import LazyLoad from 'react-lazy-load';

import backgroundImage from '../../assets/info/infoBackground.webp';
import durango1 from '../../assets/info/durango/Establecimiento_1.webp';
import durango2 from '../../assets/info/durango/Establecimiento_2.webp';
import durango3 from '../../assets/info/durango/Establecimiento_3.webp';
import durango4 from '../../assets/info/durango/Establecimiento_4.webp';
import durango5 from '../../assets/info/durango/Establecimiento_5.webp';
import durango6 from '../../assets/info/durango/Establecimiento_6.webp';
import durango7 from '../../assets/info/durango/Establecimiento_7.webp';

import mazatlan1 from '../../assets/info/mazatlan/Mazartlan_1.jpg';
import mazatlan2 from '../../assets/info/mazatlan/Mazartlan_2.jpg';
import mazatlan3 from '../../assets/info/mazatlan/Mazartlan_3.jpg';
import mazatlan4 from '../../assets/info/mazatlan/Mazartlan_4.jpg';
import mazatlan5 from '../../assets/info/mazatlan/Mazartlan_5.jpg';
import mazatlan6 from '../../assets/info/mazatlan/Mazartlan_6.jpg';
import mazatlan7 from '../../assets/info/mazatlan/Mazartlan_7.jpg';

import tmbDurango1 from '../../assets/info/durango/Establecimiento_1_tmb.webp';
import tmbDurango2 from '../../assets/info/durango/Establecimiento_2_tmb.webp';
import tmbDurango3 from '../../assets/info/durango/Establecimiento_3_tmb.webp';
import tmbDurango4 from '../../assets/info/durango/Establecimiento_4_tmb.webp';
import tmbDurango5 from '../../assets/info/durango/Establecimiento_5_tmb.webp';
import tmbDurango6 from '../../assets/info/durango/Establecimiento_6_tmb.webp';
import tmbDurango7 from '../../assets/info/durango/Establecimiento_7_tmb.webp';

import './UsersInfo.css';
import widthHeightSelector from '../../redux/selectors/widthHeightSelector';
import restaurantSelector from '../../redux/selectors/restaurantSelector';

function UsersInfo({ isScrolled }) {
  const { width } = useSelector(widthHeightSelector);
  const { number } = useSelector(restaurantSelector);

  const [index, setIndex] = useState(-1);

  const photos = [
    {
      src: number === 1 ? durango1 : mazatlan1,
      width: 3666,
      height: 2444,
      alt: 'Foto del establecimiento',
    },
    {
      src: number === 1 ? durango5 : mazatlan2,
      width: 2626,
      height: 1751,
      alt: 'Foto del establecimiento',
    },

    {
      src: number === 1 ? durango3 : mazatlan3,
      width: 3502,
      height: 2334,
      alt: 'Foto del establecimiento',
    },
    {
      src: number === 1 ? durango4 : mazatlan4,
      width: 4209,
      height: 1460,
      alt: 'Foto del establecimiento',
    },
    {
      src: number === 1 ? durango2 : mazatlan5,
      width: 3666,
      height: 2444,
      alt: 'Foto del establecimiento',
    },
    {
      src: number === 1 ? durango6 : mazatlan6,
      width: 2719,
      height: 1812,
      alt: 'Foto del establecimiento',
    },

    {
      src: number === 1 ? durango7 : mazatlan7,
      width: 3471,
      height: 1303,
      alt: 'Foto del establecimiento',
    },
  ];
  const thumbnails = [
    {
      src: number === 1 ? tmbDurango1 : mazatlan1,
      width: 3666,
      height: 2444,
      alt: 'Foto del establecimiento',
    },
    {
      src: number === 1 ? tmbDurango5 : mazatlan2,
      width: 2626,
      height: 1751,
      alt: 'Foto del establecimiento',
    },

    {
      src: number === 1 ? tmbDurango3 : mazatlan3,
      width: 3502,
      height: 2334,
      alt: 'Foto del establecimiento',
    },
    {
      src: number === 1 ? tmbDurango4 : mazatlan4,
      width: 4209,
      height: 1460,
      alt: 'Foto del establecimiento',
    },
    {
      src: number === 1 ? tmbDurango2 : mazatlan5,
      width: 3666,
      height: 2444,
      alt: 'Foto del establecimiento',
    },
    {
      src: number === 1 ? tmbDurango6 : mazatlan6,
      width: 2719,
      height: 1812,
      alt: 'Foto del establecimiento',
    },

    {
      src: number === 1 ? tmbDurango7 : mazatlan7,
      width: 3471,
      height: 1303,
      alt: 'Foto del establecimiento',
    },
  ];
  return (
    <Flex
      id="facilities"
      align="center"
      vertical
      style={{
        backgroundImage: `${isScrolled ? `url(${backgroundImage})` : ''}`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        width: '100%',
      }}
    >
      <Flex vertical justify="center" style={{ marginTop: '10px', maxWidth: 1000, position: 'relative', padding: '20px', background: 'rgba(0, 0, 0, 0.5)', borderRadius: '10px' }}>
        <Typography.Title level={width > 900 ? 1 : 3} className="m-0" style={{ color: 'white', textAlign: 'center' }}>
          Nuestras instalaciones
        </Typography.Title>
        <Typography.Paragraph style={{ color: 'white', textAlign: 'justify', marginTop: '10px', fontSize: '18px', lineHeight: '1.6' }}>
          En Taquito Grill, nos enorgullece ser uno de los mejores restaurantes en Durango y Mazatlán, ofreciendo una atmósfera familiar y divertida.
          Nuestros restaurantes cuenta con un bar donde puedes disfrutar de eventos deportivos en nuestras pantallas,
          y una área infantil diseñada para que los más pequeños se diviertan de manera segura mientras los adultos disfrutan
          de la comida y la compañía. Es el lugar perfecto para todo tipo de celebraciones y eventos especiales.
        </Typography.Paragraph>
      </Flex>
      <div className="images-container">
        <LazyLoad>
          <PhotoAlbum
            layout="rows"
            photos={thumbnails}
            onClick={({ index: i }) => setIndex(i)}
          />
        </LazyLoad>
        <Lightbox
          slides={photos}
          open={index >= 0}
          index={index}
          close={() => setIndex(-1)}
        />
      </div>
    </Flex>
  );
}

export default UsersInfo;
