import { DELETE_ADMIN_DATA, SET_ADMIN_DATA } from '../actions/adminActions';

const initialState = {
  id: null,
  token: null,
  idRestaurant: null,
  email: null,
  isSuper: null,
  name: null,
};

// eslint-disable-next-line default-param-last
const adminReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ADMIN_DATA:
      return {
        ...state,
        id: action.payload.id,
        token: action.payload.token,
        idRestaurant: action.payload.idRestaurant,
        email: action.payload.email,
        isSuper: action.payload.isSuper,
        name: action.payload.name,
      };
    case DELETE_ADMIN_DATA:
      return null;
    default:
      return state;
  }
};

export default adminReducer;
