import React, { useMemo } from 'react';
import { Flex, Skeleton } from 'antd';
import { useSelector } from 'react-redux';
import UsersTwoTeamsCard from './UsersTwoTeamsCard';
import UsersEmptyCard from './UsersEmptyCard';
import widthHeightSelector from '../../redux/selectors/widthHeightSelector';
import UsersCardShowMore from './UsersCardShowMore';
import UsersF1Card from './UsersF1Card';

const cardWidth = 350;
const cardHeight = 120;

function UsersCalendarCards({ data, isLoading, fromHomeCalendar, type, dates }) {
  const { width, height } = useSelector(widthHeightSelector);

  const visibleCards = useMemo(() => {
    if (fromHomeCalendar) return data.sort((a, b) => (a.startTime > b.startTime ? 1 : -1));
    const cardsPerRow = Math.floor(width / cardWidth);
    const availableHeight = height - 400;
    const numberOfCardsInAvailableHeight = Math.floor(availableHeight / cardHeight);
    let numberOfVisibleCards = cardsPerRow * numberOfCardsInAvailableHeight;
    if (numberOfVisibleCards === 0)numberOfVisibleCards = 3;
    return data.sort((a, b) => (a.startTime > b.startTime ? 1 : -1)).slice(0, numberOfVisibleCards);
  }, [data, fromHomeCalendar, width]);

  if (isLoading) return <Skeleton />;

  return (
    <Flex vertical justify="space-around" className="h-100">
      <Flex wrap="wrap" justify="center" style={{ overflow: 'auto' }}>
        {visibleCards.map((item) => (
          (item.numberSport === 4)
            ? <UsersF1Card item={item} />
            : <UsersTwoTeamsCard item={item} />
        ))}
        {((!fromHomeCalendar && data?.length < 2) || fromHomeCalendar) && <UsersEmptyCard />}
      </Flex>
      {!fromHomeCalendar && (<UsersCardShowMore type={type} dates={dates} />)}
    </Flex>
  );
}

export default UsersCalendarCards;
