import { Card, Flex, Typography } from 'antd';
import Meta from 'antd/es/card/Meta';
import React from 'react';

import { useSelector } from 'react-redux';
import LazyLoad from 'react-lazy-load';
import restaurantSelector from '../../redux/selectors/restaurantSelector';

import './UsersShowMore.css';

import areaDgo from '../../assets/text/durango/Area.webp';
import ambienteDgo from '../../assets/text/durango/Ambiente.webp';
import musicaDgo from '../../assets/text/durango/Musica.webp';

import areaMzt from '../../assets/text/mazatlan/Area.webp';
import ambienteMzt from '../../assets/text/mazatlan/Ambiente.webp';

function UsersShowMore() {
  const { number } = useSelector(restaurantSelector);

  return (
    <Flex vertical align="around" className="pb-3" style={{ backgroundColor: '#EEEEEE' }}>
      <Flex vertical className="container">

        <Typography.Title level={3} style={{ textAlign: 'center', marginTop: '20px', marginBottom: '20px' }}>
          Descubre más en Taquito Grill
        </Typography.Title>
        <Flex justify="space-around" wrap="wrap">
          <Card
            hoverable
            cover={(
              <LazyLoad style={{ objectFit: 'cover', width: '100%', height: '100%' }}>
                <img alt="Ambiente" src={number === 1 ? ambienteDgo : ambienteMzt} style={{ objectFit: 'cover', width: '100%', height: '100%' }} />
              </LazyLoad>
            )}
            className="show-card"
          >
            <Meta
              title="Ambiente Familiar y Deportivo"
              description={`En Taquito Grill, creemos que una buena comida debe ir acompañada de un ambiente vibrante.
            Disfruta de nuestro bar deportivo donde podrás animar a tu equipo favorito en nuestras pantallas ${number === 2 ? 'gigantes' : ''}.
            Ya sea para un partido de fútbol, una pelea de boxeo u otro evento deportivo, nuestro bar es el lugar ideal para disfrutarlo al máximo.`}
            />
          </Card>
          <Card
            hoverable
            cover={(
              <LazyLoad style={{ objectFit: 'cover', width: '100%', height: '100%' }}>
                <img loading="lazy" src={number === 1 ? areaDgo : areaMzt} alt="Área infantil" style={{ objectFit: 'cover', width: '100%', height: '100%' }} />
              </LazyLoad>
            )}
            className="show-card"

          >
            <Meta
              title="Área Infantil y Ludoteca"
              description="Sabemos lo importante que es para los padres que sus hijos se diviertan mientras disfrutan de una comida en familia.
            Por eso, en Taquito Grill, contamos con una increíble área infantil y ludoteca. Los niños pueden jugar de forma segura
            y participar en actividades divertidas mientras los adultos disfrutan de su comida y conversación."
            />
          </Card>
          <Card
            hoverable
            cover={(
              <LazyLoad style={{ objectFit: 'cover', width: '100%', height: '100%' }}>
                <img loading="lazy" alt="Música en vivo" src={musicaDgo} style={{ objectFit: 'cover', width: '100%', height: '100%' }} />
              </LazyLoad>
            )}
            className="show-card"
            hidden={number !== 1}
          >
            <Meta
              title="Música en Vivo y Eventos Especiales"
              description="Los fines de semana, Taquito Grill cobra vida con música en vivo. Ven y disfruta de tus bandas locales favoritas
            mientras saboreas nuestros platos. Además, nuestro espacio es perfecto para celebrar cumpleaños, reuniones familiares
            y cualquier evento especial. Nuestro equipo se encargará de hacer que tu celebración sea memorable."
            />
          </Card>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default UsersShowMore;
