import dayjs from 'dayjs';

function isValidDates(datesString) {
  if (!datesString) return false;
  try {
    const datesArray = JSON.parse(datesString);
    if (!Array.isArray(datesArray) || datesArray.length !== 2) {
      return false;
    }
    const [startDate, endDate] = datesArray;
    const isStartDateValid = dayjs(startDate).isValid();
    const isEndDateValid = dayjs(endDate).isValid();
    if (!isStartDateValid || !isEndDateValid) {
      return false;
    }
    return [dayjs(startDate), dayjs(endDate)];
  } catch (error) {
    return false;
  }
}

export default isValidDates;
