import Swal from 'sweetalert2';
import { deleteAdminData } from '../redux/actions/adminActions';
import './taquitoCatchRequest.css';

const taquitoCatchRequest = (err, dispatch, navigate) => {
  if (err?.message === 'Network Error') {
    Swal.fire(
      'Error de red',
      'Por favor verifique su conexión a internet',
      'error',
    );
  } else if (err.response?.status === 401) {
    Swal.fire(
      'No Autorizado (401)',
      'error',
    );
  } else if (err.response?.status === 403) {
    Swal.fire(
      'Sesión expirada',
      'Por favor vuelva a iniciar sesión',
      'info',
    ).then(() => {
      dispatch(deleteAdminData());
      navigate('/login');
      window.location.reload();
    });
  } else if (err.response?.status === 408) {
    Swal.fire(
      '¡La conexión tardó demasiado en responder!',
      'Actualize la página y revise su conexión a internet.',
      'error',
    );
  } else if (err.response?.status === 429) {
    Swal.fire(
      'Recurso bloqueado',
      'Este servicio está bloqueado a cierto número de solicitudes por periodo. ',
      'info',
    );
  } else {
    Swal.fire(
      'Error',
      err.message || 'Ocurrió un error.',
      'error',
    );
  }
};
export default taquitoCatchRequest;
