import React from 'react';
import { Flex, Typography } from 'antd';
import { MailOutlined, PhoneOutlined, EnvironmentOutlined, FacebookOutlined, InstagramOutlined, WhatsAppOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import restaurantSelector from '../../redux/selectors/restaurantSelector';

import './UsersVisitUs.css';
import formatPhoneNumber from '../../utils/formatPhoneNumber';

function UsersVisitUs({ isScrolled }) {
  const { mapsSrc, email, phoneNumber, address, facebook, instagram, mapsDir, number } = useSelector(restaurantSelector);

  return (
    <div id="contact" className="visit-us">
      <div className="overlay">
        <Flex justify="around" wrap="wrap" className="w-100 contact-info">
          <Flex vertical style={{ flexBasis: '50%', flexGrow: 1 }}>
            <Typography.Title level={2} style={{ color: 'white', margin: 0 }}>Contacto</Typography.Title>
            <Typography.Paragraph hidden={number !== 1} style={{ textAlign: 'center', fontSize: '18px', margin: '10px 0', color: 'white' }}>
              Uno de los mejores lugares para comer en Durango.
            </Typography.Paragraph>
            <Typography.Paragraph hidden={number !== 2} style={{ textAlign: 'center', fontSize: '18px', margin: '10px 0', color: 'white' }}>
              Uno de los mejores lugares para comer en Mazatlán.
            </Typography.Paragraph>
            <Flex wrap="wrap" justify="around" className="w-100">
              <Flex vertical className="info-block" align="center">
                <a role="button" href={mapsDir} target="_blank" rel="noopener noreferrer" aria-label="Abrir en Google Maps" style={{ textDecoration: 'none' }} className="visit-large-icons visit-hoverable">
                  <EnvironmentOutlined className="m-0" />
                </a>
                <a role="button" href={mapsDir} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                  <Typography.Title level={4} style={{ color: '#AAAAAA' }}>Visítanos</Typography.Title>
                </a>
                <p style={{ textAlign: 'center' }}>{address}</p>
              </Flex>
              <Flex vertical className="info-block" align="center">
                <a aria-label="Enviar correo" role="button" href={`mailto: ${email}`} target="_blank" rel="noopener noreferrer" className="visit-large-icons visit-hoverable">
                  <MailOutlined className="m-0" />
                </a>
                <a aria-label="Enviar correo" role="button" href={`mailto: ${email}`} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                  <Typography.Title level={4} style={{ color: '#AAAAAA' }}>Contáctanos</Typography.Title>
                </a>
                <p>{email}</p>
              </Flex>
            </Flex>
            <Flex wrap="wrap" justify="around" className="w-100" align="center">
              <Flex vertical className="info-block" align="center">
                <a href={`tel:${formatPhoneNumber(phoneNumber)}`} rel="noopener noreferrer" aria-label="Llámanos" style={{ textDecoration: 'none' }} className="visit-large-icons visit-hoverable">
                  <PhoneOutlined className="m-0" />
                </a>
                <a href={`tel:${formatPhoneNumber(phoneNumber)}`} rel="noopener noreferrer" aria-label="Llámanos" style={{ textDecoration: 'none' }}>
                  <Typography.Title level={4} style={{ color: '#AAAAAA' }}>Llámanos</Typography.Title>
                </a>
                <p>{formatPhoneNumber(phoneNumber)}</p>
              </Flex>
              <Flex vertical className="info-block" align="center">
                <a href={`https://wa.me/52${number === 1 ? '6181697164' : '6692504030'}`} target="_blank" rel="noopener noreferrer" aria-label="Escríbenos" style={{ textDecoration: 'none' }} className="visit-large-icons visit-hoverable">
                  <WhatsAppOutlined className="m-0" />
                </a>
                <a href={`https://wa.me/52${number === 1 ? '6181697164' : '6692504030'}`} target="_blank" rel="noopener noreferrer" aria-label="Escríbenos" style={{ textDecoration: 'none' }}>
                  <Typography.Title level={4} style={{ color: '#AAAAAA' }}>Escríbenos</Typography.Title>
                </a>
                <p>{number === 1 ? '618 169 7164' : '669 250 4030'}</p>
              </Flex>
            </Flex>
            <Flex vertical className="info-block" align="center">
              <Flex className="social-media">
                <a href={facebook} target="_blank" rel="noopener noreferrer" className="visit-hoverable visit-icons" aria-label="Facebook Taquito Grill">
                  <FacebookOutlined />
                </a>
                <a href={instagram} target="_blank" rel="noopener noreferrer" className="visit-hoverable visit-icons" aria-label="Instagram Taquito Grill">
                  <InstagramOutlined />
                </a>
              </Flex>
              <Typography.Title level={4} style={{ color: '#AAAAAA' }}>Síguenos</Typography.Title>
            </Flex>
          </Flex>
          <Flex style={{ flexBasis: '50%', flexGrow: 1 }}>
            {isScrolled
              ? (
                <iframe
                  className="w-100 h-100"
                  style={{ borderRadius: '10px' }}
                  title="Location"
                  src={mapsSrc}
                  allowFullScreen
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                />
              )
              : null}
          </Flex>
        </Flex>
      </div>
    </div>
  );
}

export default UsersVisitUs;
