import { useEffect } from 'react';
import { decodeToken } from 'react-jwt';
import { useDispatch } from 'react-redux';
import { setAdminData } from '../redux/actions/adminActions';

const useToken = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      const { id, idRestaurant, email: registeredEmail, super: isSuper, name } = decodeToken(token);
      dispatch(setAdminData(id, token, idRestaurant, registeredEmail, !!isSuper, name));
    }
  }, []);
};
export default useToken;
