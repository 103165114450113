/* eslint-disable import/no-unresolved */
import { Flex, Image, Skeleton, Typography } from 'antd';
import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, EffectCoverflow, Autoplay } from 'swiper/modules';
import { useSelector } from 'react-redux';
import colors from '../../utils/colors';
import restaurantSelector from '../../redux/selectors/restaurantSelector';

import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/autoplay';

import './UsersGallery.css';

const slideStyle = { backgroundColor: 'white', boxShadow: '0 0 20px rgba(0, 0, 0, 0.1)', borderRadius: '2rem' };

function UsersGallery() {
  const { promotions, isLoading, number } = useSelector(restaurantSelector);

  if (isLoading) {
    return (
      <Flex id="gallery" className="bg-light" style={{ minWidth: '100vh' }}>
        <Skeleton />
      </Flex>
    );
  }

  if (!promotions.length) return null;

  return (
    <Flex vertical align="around" id="gallery" className="bg-light pb-3 ">
      <Flex className="p-1" vertical align="center">
        <Typography.Title className="mx-2 my-0 mt-4" level={2}>Promociones</Typography.Title>
        <Typography.Title hidden={number !== 1} className="mx-3 my-1 mb-5" level={4} style={{ color: colors.primary[0], textAlign: 'center' }}>
          Disfruta cada partido con nuestras promociones exclusivas en nuestro restaurant bar en Durango.
        </Typography.Title>
        <Typography.Title hidden={number !== 2} className="mx-3 my-1 mb-5" level={4} style={{ color: colors.primary[0], textAlign: 'center' }}>
          Disfruta cada partido con nuestras promociones exclusivas en nuestro restaurant bar en Mazatlán.
        </Typography.Title>
      </Flex>
      <Swiper
        effect="coverflow"
        grabCursor
        centeredSlides
        loop
        autoplay
        slidesPerView="auto"
        coverflowEffect={{
          rotate: 0,
          stretch: 0,
          depth: 100,
          modifier: 2.5,
        }}
        scrollbar={{ draggable: true }}
        pagination={{ el: '.swiper-pagination', clickable: true }}
        modules={[Autoplay, EffectCoverflow, Pagination, Navigation]}
        className="swiper-slide-custom"
      >
        {promotions.map(({ pictureURL }) => (
          <SwiperSlide style={slideStyle}>
            <div className="w-100 h-100">
              <Image height="100%" width="100%" src={pictureURL} alt="Foto con promoción" />
            </div>
          </SwiperSlide>
        ))}
        <div className="slider-controler">
          <div className="swiper-button-prev slider-arrow">
            <ion-icon name="arrow-back-outline" />
          </div>
          <div className="swiper-button-next slider-arrow">
            <ion-icon name="arrow-forward-outline" />
          </div>
          <div className="swiper-pagination" />
        </div>
      </Swiper>
    </Flex>
  );
}

export default UsersGallery;
