import React from 'react';
import { Layout, Row, Col, Flex, Button, Space } from 'antd';
import {
  EnvironmentOutlined,
  MailOutlined,
  PhoneOutlined,
  FacebookOutlined,
  InstagramOutlined,
  WhatsAppOutlined,
} from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import logo from '../../assets/logo tinta.png'; // Asegúrate de que esta es la ruta correcta a tu logo
import colors from '../../utils/colors';
import restaurantSelector from '../../redux/selectors/restaurantSelector';
import formatPhoneNumber from '../../utils/formatPhoneNumber';

const { Footer } = Layout;

const iconStyle = {
  fontSize: '35px',
  marginRight: '20px',
  color: '#B9BDC0',
};

function UsersFooter({ isScrolled }) {
  const { number, email, phoneNumber, address, facebook, instagram, mapsDir } = useSelector(restaurantSelector);
  const navigate = useNavigate();

  const handleOpen = (route) => {
    navigate(route);
  };

  return (
    <>
      <style>
        {`
          .hoverable {
            text-decoration:none;
            color: #B9BDC0;
          }
          .hoverable:hover {
            color: ${colors.primary[0]};
            cursor: pointer;
          }
          .footer-icon:hover {
            transform: scale(1.25); /* Efecto de escala para el hover */
          }
        `}
      </style>
      <Footer style={{ background: '#222', padding: '20px 50px', color: '#B9BDC0' }}>
        <Row justify="center" style={{ marginBottom: '20px' }}>
          <Col xs={24} style={{ textAlign: 'center' }}>
            {isScrolled
              ? <img src={logo} alt="Logo Taquito Grill" style={{ width: '150px' }} />
              : null}
          </Col>
        </Row>
        <Row justify="space-between" align="top">
          <Col xs={24} sm={12} md={8} lg={8}>
            <div style={{ color: colors.primary[0], fontWeight: 'bold', fontSize: '17px', marginBottom: '16px' }}>Estemos en contacto</div>
            <Flex vertical>
              <a className="mb-3" href={mapsDir} target="_blank" rel="noopener noreferrer" aria-label="Abrir en maps" style={{ textDecoration: 'none', color: '#B9BDB6' }}>
                <Space>
                  <EnvironmentOutlined style={{ color: colors.primary[0] }} />
                  {address}
                </Space>
              </a>
              <a className="mb-3" href={`mailto: ${email}`} target="_blank" rel="noopener noreferrer" aria-label="Enviar correo" style={{ textDecoration: 'none', color: '#B9BDB6' }}>
                <Space>
                  <MailOutlined style={{ color: colors.primary[0] }} />
                  {email}
                </Space>
              </a>
              <a className="mb-3" href={`tel:${formatPhoneNumber(phoneNumber)}`} rel="noopener noreferrer" aria-label="Llámanos" style={{ textDecoration: 'none', color: '#B9BDB6' }}>
                <Space>
                  <PhoneOutlined style={{ color: colors.primary[0] }} />
                  {formatPhoneNumber(phoneNumber)}
                </Space>
              </a>
              <a className="mb-3" href={`https://wa.me/52${number === 1 ? '6181697164' : '6692504030'}`} target="_blank" rel="noopener noreferrer" aria-label="Escríbenos" style={{ textDecoration: 'none', color: '#B9BDB6' }}>
                <Space>
                  <WhatsAppOutlined style={{ color: colors.primary[0] }} />
                  {number === 1 ? '618 169 7164' : '669 250 4030'}
                </Space>
              </a>
            </Flex>
          </Col>
          <Col xs={24} sm={12} md={8} lg={8} style={{ textAlign: 'center' }}>
            <Flex vertical>
              <span style={{ color: colors.primary[0], fontWeight: 'bold', fontSize: '17px', marginBottom: '10px' }}>Conócenos</span>
              <Button type="link" className="hoverable" href="/#welcome">Inicio</Button>
              <Button type="link" className="hoverable" onClick={() => handleOpen('/calendario')}>Calendario</Button>
              <Button type="link" className="hoverable" onClick={() => handleOpen('/promociones')}>Promociones</Button>
              <a
                role="button"
                href={`https://${number === 1 ? 'dgo' : 'mzt'}.taquitogrill.com`}
                style={{ textDecoration: 'none', color: '#ABBDC0' }}
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex justify-content-center align-items-center"
              >
                Ver menú
              </a>
              <Button type="link" className="hoverable" onClick={() => handleOpen('/instalaciones')}>Instalaciones</Button>
              <Button type="link" className="hoverable" onClick={() => handleOpen('/contacto')}>Contacto</Button>
            </Flex>
          </Col>
          <Col xs={24} sm={24} md={8} lg={8} style={{ textAlign: 'center' }}>
            <div style={{ color: colors.primary[0], fontWeight: 'bold', fontSize: '17px', marginBottom: '16px' }}>Síguenos en redes sociales</div>
            <a href={facebook} target="_blank" rel="noopener noreferrer" className="footer-icon hoverable" style={iconStyle} aria-label="Facebook Taquito Grill">
              <FacebookOutlined />
            </a>
            <a href={instagram} target="_blank" rel="noopener noreferrer" className="footer-icon" style={iconStyle} aria-label="Instagram Taquito Grill">
              <InstagramOutlined />
            </a>
          </Col>
        </Row>
        <Row justify="center">
          <Col xs={24} style={{ textAlign: 'center', color: '#676767' }}>
            <p>
              Desarrollado por
              {' '}
              <a href="https://aodev.com.mx" target="_blank" rel="noopener noreferrer" style={{ color: '#B9BDC0' }}>Aodev</a>
            </p>
          </Col>
          <Col xs={24} style={{ textAlign: 'center', color: '#676767' }}>
            <p>© 2024 Taquito Grill. Todos los derechos reservados.</p>
          </Col>
        </Row>
      </Footer>
    </>
  );
}

export default UsersFooter;
