import { Flex, Radio, Space, Typography } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EnvironmentOutlined } from '@ant-design/icons';
import restaurantSelector from '../../redux/selectors/restaurantSelector';
import { setRestaurant } from '../../redux/actions/restaurantActions';

import colors from '../../utils/colors';
import './UsersWelcome.css';

function UsersWelcome() {
  const { number } = useSelector(restaurantSelector);
  const dispatch = useDispatch();

  const handleChange = (e) => {
    dispatch(setRestaurant(e.target.value));
  };

  return (
    <div id="welcome" className="container w-100 h-100">
      <Flex className="container-welcome" vertical align="start" justify="center" style={{ minHeight: '100%' }}>
        <Typography.Title level={1} style={{ color: 'white' }} className="title-welcome">
          TU RESTAURANTE DEPORTIVO
        </Typography.Title>
        <Typography.Title level={2} style={{ color: 'white' }} className="subtitle-welcome">
          DISFRUTA LOS MEJORES TACOS, HAMBURGUESAS Y CORTES MIENTRAS VES TUS DEPORTES FAVORITOS.
          {' '}
        </Typography.Title>
        <Typography.Title level={2} style={{ color: 'white' }} className="subsubtitle-welcome">
          Conoce nuestras sucursales:
        </Typography.Title>
        <Flex className="container-restaurant">
          <Radio.Group value={number} buttonStyle="solid" onChange={handleChange} style={{ display: 'flex' }} className="radio-group-welcome">
            <Radio.Button
              style={{ borderTopLeftRadius: 100, borderBottomLeftRadius: 100, textAlign: 'center', color: 'black', fontWeight: '600' }}
              value={1}
              className="radio-welcome"
            >
              DURANGO
            </Radio.Button>
            <Radio.Button
              style={{ borderTopRightRadius: 100, borderBottomRightRadius: 100, textAlign: 'center', color: 'black', fontWeight: '600' }}
              value={2}
              className="radio-welcome"
            >
              MAZATLÁN
            </Radio.Button>
          </Radio.Group>
        </Flex>
        <Flex justify="start">
          <a
            href={number === 1
              ? 'https://maps.app.goo.gl/mtDZspL5ERmTcvU98'
              : 'https://maps.app.goo.gl/AtvfKGwNJGC21bUS6'}
            target="_blank"
            rel="noopener noreferrer"
            style={{ fontWeight: 600, borderRadius: 100, color: '#0F0F0F', backgroundColor: colors.primary[0], textDecoration: 'none' }}
            aria-label="Ubicación taquito grill"
            className="d-flex justify-content-center align-items-center button-welcome"
          >
            <Space>
              <EnvironmentOutlined />
              VISÍTANOS
            </Space>
          </a>
        </Flex>
      </Flex>
    </div>
  );
}

export default UsersWelcome;
