import React, { lazy, Suspense } from 'react';
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';
import { useSelector } from 'react-redux';
import useToken from '../hooks/useToken';
import adminSelector from '../redux/selectors/adminSelector';
import LazyLoading from '../components/LazyLoading';
import HomeUsers from '../pages/HomeUsers';
import HomeVisitUs from '../pages/HomeVisitUs';
import HomeGallery from '../pages/HomeGallery';
import HomeInfo from '../pages/HomeInfo';
import HomeCalendar from '../pages/HomeCalendar';

const HomeAdmin = lazy(() => import('../pages/HomeAdmin'));
const Login = lazy(() => import('../pages/Admin/Login'));
const NotFound = lazy(() => import('../pages/NotFound'));

function Router() {
  const id = useSelector(adminSelector)?.id;
  useToken();

  const router = createBrowserRouter([
    {
      path: '/',
      element: (
        <HomeUsers />
      ),
    },
    {
      path: '/calendario',
      element: (
        <HomeCalendar />
      ),
    },
    {
      path: '/durango',
      element: (
        <HomeUsers />
      ),

    },
    {
      path: '/durango/calendario',
      element: (
        <HomeCalendar />
      ),
      children: [
        {
          path: ':dateType/:date',
          element: (
            <HomeCalendar />
          ),
        },
      ],
    },
    {
      path: '/mazatlan',
      element: (
        <HomeUsers />
      ),
    },
    {
      path: '/mazatlan/calendario',
      element: (
        <HomeCalendar />
      ),
      children: [
        {
          path: ':dateType/:date',
          element: (
            <HomeCalendar />
          ),
        },
      ],
    },
    {
      path: '/contacto',
      element: (
        <HomeVisitUs />
      ),
    },
    {
      path: '/promociones',
      element: (
        <HomeGallery />
      ),
    },
    {
      path: '/instalaciones',
      element: (
        <HomeInfo />
      ),
    },
    {
      path: '/login',
      element: (
        (id)
          ? <Navigate replace to="/gallery" />
          : (
            <Suspense fallback={<LazyLoading />}>
              <Login />
            </Suspense>
          )

      ),
    },
    ...['/gallery', '/information', '/admins', '/calendar'].map((path) => ({
      path,
      element: (
        (!id)
          ? <Navigate replace to="/login" />
          : (
            <Suspense fallback={<LazyLoading />}>
              <HomeAdmin />
            </Suspense>
          )

      ),
    })),
    {
      path: '/*',
      element: (
        <Suspense fallback={<LazyLoading />}>
          <NotFound />
        </Suspense>
      ),
    },
  ]);

  return (
    <RouterProvider router={router} />
  );
}

export default Router;
