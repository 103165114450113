import { Radio } from 'antd';
import React from 'react';

function UsersCalendarType({ dates, setDates, type, setType }) {
  const onChange = (e) => {
    setType(e.target.value);
    setDates([dates[0].startOf(e.target.value), dates[0].endOf(e.target.value)]);
  };

  return (
    <Radio.Group size="large" style={{ borderRadius: '5px', marginBottom: 5 }} onChange={onChange} defaultValue={type}>
      <Radio.Button value="day">Diario</Radio.Button>
      <Radio.Button value="week">Semanal</Radio.Button>
      <Radio.Button value="month">Mensual</Radio.Button>
    </Radio.Group>
  );
}

export default UsersCalendarType;
