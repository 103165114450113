import { Button, Flex } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import restaurantSelector from '../../redux/selectors/restaurantSelector';

function UsersCardShowMore({ type, dates }) {
  const { number } = useSelector(restaurantSelector);
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/${number === 1 ? 'durango' : 'mazatlan'}/calendario/${type}/${JSON.stringify(dates)}`);
  };

  return (
    <Flex justify="center">
      <Button onClick={handleClick} style={{ width: 200 }} type="primary">Ver calendario completo</Button>
    </Flex>
  );
}

export default UsersCardShowMore;
