import React, { useEffect, useRef } from 'react';
import { Layout } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { setWidthHeight } from '../redux/actions/widthHeightActions';
import getWindowSize from '../utils/getWindowSize';
import UsersInfo from './Users/UsersInfo';
import UsersHeader from './Users/UsersHeader';
import useGetRequest from '../hooks/useGetRequest';
import { setAllData } from '../redux/actions/restaurantActions';
import restaurantSelector from '../redux/selectors/restaurantSelector';
import UsersFooter from './Users/UsersFooter';

function HomeInfo() {
  const { number } = useSelector(restaurantSelector);
  const { data, isLoading, refetch } = useGetRequest('restaurantData', '/taquito/users/restaurant/info', { number }, true, false);
  const dispatch = useDispatch();
  const { name, facebook, email, instagram, phoneNumber, address, mapsSrc, mapsDir } = data?.info || {};
  const firstPaint = useRef(true);

  useEffect(() => {
    if (!isLoading) dispatch(setAllData(name, facebook, email, instagram, phoneNumber, address, mapsSrc, mapsDir, data.promotions || []));
  }, [data]);

  useEffect(() => {
    if (firstPaint.current)firstPaint.current = false;
    else refetch();
  }, [number]);

  useEffect(() => {
    const handleWindowResize = () => {
      const { width: width2, height } = getWindowSize();
      dispatch(setWidthHeight(width2, height));
    };
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  return (
    <Layout
      style={{ height: '100vh' }}
    >
      <Helmet>
        <title>Taquito Grill - Instalaciones</title>
        <meta name="description" content="Descubre nuestras instalaciones: modernas, acogedoras y listas para crear momentos inolvidables." />
      </Helmet>
      <UsersHeader isScrolled />
      <Content
        style={{ overflow: 'auto', position: 'relative', zIndex: 1 }}
      >
        <UsersInfo isScrolled />
        <UsersFooter isScrolled />
      </Content>
    </Layout>
  );
}

export default HomeInfo;
