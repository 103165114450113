import isFirstRouteDurango from '../../utils/isFirstRouteDurango';
import { SET_DATA, SET_RESTAURANT } from '../actions/restaurantActions';

const initialState = {
  isLoading: true,
  number: isFirstRouteDurango(window.location.pathname) ? 1 : 2,
  name: null,
  facebook: null,
  email: null,
  instagram: null,
  phoneNumber: null,
  address: null,
  mapsSrc: null,
  mapsDir: null,
  promotions: [],
};

// eslint-disable-next-line default-param-last
const restaurantReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_RESTAURANT:
      return {
        ...state,
        number: action.payload.number,
      };
    case SET_DATA:
      return {
        ...state,
        isLoading: false,
        name: action.payload.name,
        facebook: action.payload.facebook,
        email: action.payload.email,
        instagram: action.payload.instagram,
        phoneNumber: action.payload.phoneNumber,
        address: action.payload.address,
        mapsSrc: action.payload.mapsSrc,
        mapsDir: action.payload.mapsDir,
        promotions: action.payload.promotions,
      };
    default:
      return state;
  }
};

export default restaurantReducer;
